@import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 3.5rem;
}
h2{
  font-size: 3.0rem;
}
h3{
  font-size: 2.5rem;
}
h4{
  font-size: 2.0rem;
}
h5{
  font-size: 1.5rem;
}
h6{
  font-size: 1.0rem;
}
body{
  font-family: 'Quicksand', Opensans, monospace;
}

.home{
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 30s linear;
            animation: App-logo-spin infinite 30s linear;
  }
}

.App-header {
  background-color: rgb(230, 244, 248);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
 
  font-weight: 900;
}
.App-header h1{
  color: rgb(55, 62, 69) !important;

}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.form-inline{
    display: flex;
    flex-flow: row;
}


.result-section {
    height: 100vh;
    background: #f06464;
    background: #ffffff;
}


.result-card{
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.result-card-image{
  padding: 10px;
  /* background-color: #f06464; */

  flex: 1 1;
}
.result-card-detail{
  text-align: left;
  /* background-color: #f062f069; */
  /* width: 70%; */
  /* height: 15.0rem; */
  flex: 2 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
}


.result-card-detail-item >.result{
  font-weight: bold;
}

.ant-upload-select-picture-card{
   width: 100% !important;
}

 .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  padding: 5px;
  color: #ffffff;
  font-size: large;
}


.hero-previews{
  display: flex;
}
.hero-preview{
  background:#191c28;
  min-height: 20rem;
  min-width: 40rem;
  border-radius: 5px;
  position: relative;
  display: flex;
  padding: 15px 10px;
  align-items: center;
  box-shadow:0 4px 20px 0 rgba(17,12,46,.25);
}
.hero-preview::before{
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 40px;
    position: absolute;
    top: 20px;
    left: 25px;
    background: #ff5f56;
    box-shadow: 16px 0px 0px #ffbd2e, 32px 0px 0px #27c93f;

}
.hero-preview-content{
  align-content: center;
  margin:  0 auto ;
  width: 20rem;
  padding: 10px;
}

.btn-success{
  background-color:rgb(204, 233, 242) !important;
  color: rgb(55, 62, 69) !important;
  font-weight: 900 !important ;
  border: none !important;
}
.try{
  margin:15px 10px
}

.user-profile-container{
  margin-top: 15px;
}

.user-profile-sections{
  display: flex;
  flex-flow: column;
  margin-top: 15px;
}
.user-profile-section{
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px;
  text-align: left;
}
.user-profile-section > .content{
  text-align: left;
}
.user-profile-section > .title{
  /* background-color: yellow; */
  text-align: left;
  color: rgba(0, 0, 0, 0.45);
}

.package-cards{
  display: flex;
}
.package-card{
  width: 300px;
  margin: 16px ;
  flex: 0 1 auto;
  /* -webkit-box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15); */
}
.package-card-details{
  display: flex;
  margin-left : 10px;
  padding-top : 10px;
  border-top : 1px solid #eeeeee
}
.package-card-details> li{
  flex-grow: 1;
  flex: 1 1 auto;
  text-align:center
}
.package-card-actions{
  display: flex;
  flex-direction: row;
  float: right;
}
.package-card-actions> button{
  flex-grow: 1;
  flex: 1 1 auto;
}

.package-card-actions> button{
  margin: 5px

}


.maintenance{
  background-color: red;
    color: #ffffff;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 900;
}
.bg-black {
  background-color: #000000;
  color: #ffffff;
}
.waiting{
  position: relative;
  margin: 0 auto;
  top: 45%
}

.full-width{
  width: 100%;
}
